import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // لإعادة التوجيه بعد تسجيل الدخول
import "../App.css";
import loginImage from "../imgs/image 6.png";
import usePost from "../hooks/usePost";

const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const { data, loading, error, postData } = usePost();
  const navigate = useNavigate(); 

  const onSubmit = async (e) => {
    e.preventDefault();
    await postData(`${process.env.REACT_APP_AUTH_API_BASE_URL}/login`, formData);
  };

  useEffect(() => {
    localStorage.removeItem("token"); 
  }, []);

  useEffect(() => {
    if (data && data.token) {
      localStorage.setItem("token", data.token);
      navigate("/dashboard");
      if(data?.status === 101){
        window.location.assign(`${process.env.REACT_APP_ABSAT_URL}/tenantCreate?t=${data.token}`);
      }else{
        const appUrl = data.appUrl;
        const tenantUsername = data.tenantUsername;
        localStorage.setItem('token',data.token);
        window.location.assign(`${appUrl}/${tenantUsername}/token/${data.token}`);
      }
    }
  }, [data, navigate]);

  return (
    <section className="flex login items-center font-cairo justify-center w-screen h-screen font-Cairo bg-gray-100">
      <div className="flex flex-wrap w-full h-screen md:w-[70%] md:h-[70%] bg-white shadow-lg overflow-hidden">
        <div className="w-full md:w-1/2 bg-[#251c54] flex items-center justify-center text-white p-6 h-1/3 md:h-full">
          <img className="w-2/3 md:w-1/2" src={loginImage} alt="تسجيل الدخول" />
        </div>

        <div className="w-full md:w-1/2 flex flex-col justify-center items-center p-6 bg-white h-2/3 md:h-full">
          <div className="w-full text-center">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">تسجيل الدخول</h2>
            <p className="text-sm text-gray-500">
              <span className="text-[#251658] font-bold">أهلا بك فى نظام ابسط  اعمال !</span><br /> يرجى إدخال البريد الإلكتروني وكلمة المرور للوصول إلى حسابك.
            </p>
          </div>

          <form onSubmit={onSubmit} dir="rtl" className="w-full flex flex-col gap-4 mt-6">
            <div>
              <label className="block text-sm text-[#251658] font-bold mb-2">البريد الإلكتروني</label>
              <input
                type="text"
                required
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                className="w-full text-center font-bold px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#251c54]"
              />
            </div>
            <div>
              <label className="block text-sm text-[#251658] font-bold mb-2">كلمة المرور</label>
              <input
                type="password"
                required
                value={formData.password}
                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                className="w-full px-4 py-2 font-bold text-center border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#251c54]"
              />
            </div>
            <div className="flex w-full justify-between items-center">
              <div className="flex items-center">
                <input type="checkbox" className="mr-2" />
                <span className="text-gray-700 ms-2 text-sm">تذكرني دائماً</span>
              </div>
              <a href="#" className="text-sm text-[#251658] hover:underline">نسيت كلمة المرور؟</a>
            </div>
            <button
              type="submit"
              className="w-full md:w-[50%] mx-auto bg-[#251658] mt-5 text-white py-2 rounded-lg hover:bg-[#1d1545] transition cursor-pointer"
              disabled={loading}
            >
              {loading ? "جاري التحميل..." : "تسجيل الدخول"}
            </button>
          </form>

          {error && <p className="text-center text-[#251658] mt-3">{error}</p>}
        </div>
      </div>
    </section>
  );
};

export default Login;
