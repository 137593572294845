import { useState } from "react";
import axios from "axios";

const usePost = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const postData = async (url, payload, withHeaderJson = true) => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem("token");
    const headers = withHeaderJson
      ? {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${token}`,
        }
      : {
          Authorization: `Bearer ${token}`,
        };

    try {
      const response = await axios.post(url, payload, { headers });
      setData(response.data);
      console.log("✅ Post Request Success:", {
        URL: url,
        PAYLOAD: payload,
        RESPONSE: response.data,
      });
    } catch (err) {
      setError(err.response?.data?.message || "حدث خطأ أثناء العملية");
      console.error("❌ Post Request Error:", err.response || err.message);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, postData };
};

export default usePost;
