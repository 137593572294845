import { useState } from 'react';
import { Routes ,Route } from "react-router-dom";
import './App.css';
import axios from 'axios';
import Login from './pages/Login'
import Logout from './pages/Logout'
import Signup from './pages/Signup';

function App() {
  return (
    <>
   <section className=" dark:bg-gray-900 w-[100%] overflow-hidden bg-black ">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/user" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </section>
    </>
  );
}

export default App;
