import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Logout = () => {

    const navigate = useNavigate();

    const logoutHandler = async() => {
        await axios.post(`${process.env.REACT_APP_AUTH_API_BASE_URL}/logout`);
        localStorage.clear();
        navigate('/login');
    }
    logoutHandler();

    // useEffect(() => {
        
    // },[]);

    return (
        <div className='p-3 text-white text-center '>Logout Redirecting...</div>
    )
}

export default Logout